<template>
  <v-card class="box-shadow-soft rounded">
    <div class="stats-container d-flex">
      <!-- For each stat value -->
      <div
        v-for="(statValue, index) in statValues"
        :key="'stat-value-' + index"
        class="stat-item px-4 py-4"
        :style="{ 'width': `calc(100% / ${statValues.length})` }"
      >
        <div class="stat-item__title">
          {{ statValue.format ? nFormatter(statValue.value) : statValue.value }}
        </div>
        <div class="stat-item__text d-flex align-center pt-1 primary--text">
          <v-icon color="primary">
            {{ statValue.icon }}
          </v-icon>
          &nbsp;
          {{ statValue.text }}
          &nbsp;
        </div>
      </div>
    </div>

    <!-- Show a graph for posts over time -->
    <div
      v-if="showDayWise && daywise.posts.length && daywise.influencers.length"
      class="pt-6"
    >
      <day-wise-graph
        :series="dayWiseSeries"
        :annotations="annotations"
      />
    </div>
  </v-card>
</template>

<script>
// Import children components
const DayWiseGraph = () => import(/* webpackChunkName: "crm-day-wise" */ "@/components/crm/DayWise")

// Export the SFC
export default {
  // Name of the component
  name: "CRMOverview",

  // Register children components
  components: {
    DayWiseGraph
  },

  // Define local data variables
  data: () => ({
    posts: [],
    influencers: []
  }),

  // Accept incoming data from parent
  props: {
    module: {
      type: String,
      required: true
    },

    daywise: {
      type: Object,
      required: true
    },

    overview: {
      type: Object,
      required: true
    },

    categories: {
      type: Array,
      required: true
    },

    showDayWise: {
      type: Boolean,
      required: false,
      default: true
    },

    showInfluencers: {
      type: Boolean,
      required: false,
      default: false
    },

    showAnnotations: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  // Define readonly computable variables
  computed: {
    /**
     * Get the currently selected platform
     *
     * @returns {String|Null}
     */
    selectedPlatform() {
      return this.$store.getters[`${this.module}/selectedPlatformById`](this.overview.model.id)
    },

    /**
     * Get the total statistical values
     *
     * @returns {Array}
     */
    statValues() {
      return [
        {
          icon: "groups",
          text: "Influencers",
          value: this.categories[0].accounts,
          format: true
        },
        {
          icon: "collections",
          text: "Posts",
          value: this.categories[0].posts,
          format: true
        },
        {
          icon: "thumb_up",
          text: "Likes",
          value: this.categories[0].likes,
          format: true
        },
        {
          icon: "play_circle",
          text: "Views",
          value: this.categories[0].views,
          format: true,
          hideIfZero: true
        },
        {
          icon: "chat_bubble",
          text: "Comments",
          value: this.categories[0].comments,
          format: true,
        },
        {
          icon: "send",
          text: "Shares",
          value: this.categories[0].shares,
          format: true,
          hideIfZero: true
        },
        {
          icon: "bookmark",
          text: "Saves",
          value: this.categories[0].saves,
          format: true,
          hideIfZero: true
        },
        {
          icon: "bar_chart",
          text: "Avg Eng",
          format: false,
          value: (this.categories[0].engagement_rate * 100).toFixed(2) + "%"
        }
      ]
      .filter((search) => search.hideIfZero && search.value === 0 ? false : true)
    },

    /**
     * Get the annotations for the graph
     *
     * @returns {Array}
     */
    annotations() {
      // Show the annotations
      return {
        // The points array
        points: this.influencers.map((item) => {
          // Get the timestamp
          const timestamp = item[0]

          // The object to return
          const object = {
            x: timestamp,
            y: this.posts.find((search) => search[0] === timestamp)[1],
            marker: {
              size: 0,
              fillColor: "#FFF",
              strokeColor: "#333",
              shape: "circle",
              radius: 0,
              offsetY: 0,
              cssClass: `annotation-marker annotation-marker-avatar annotation-marker-${timestamp}`
            },
            image: {
              path: item[2],
              width: 24,
              height: 24,
              offsetY: 12,
            },
          }

          // If the item count is more than 1
          if (item[1] > 1) {
            // Add the label
            object.label = {
              borderColor: "#FF4560",
              offsetY: -6,
              style: {
                color: "#FFFFFF",
                background: "#FF4560",
              },
              text: `+${item[1] - 1}`,
            }
          }

          // Return the object
          return object
        }),
      }
    },

    /**
     * Get the day wise post values
     *
     * @returns {Array}
     */
    dayWiseSeries() {
      return [
        {
          name: "Posts",
          // type: "line",
          condition: true,
          data: this.posts
        },
        {
          name: "Influencers",
          // type: "column",
          condition: this.showInfluencers,
          data: this.influencers.map((item) => [item[0], item[1]])
        },
      ].filter((search) => search.condition)
    }
  },

  // Watch for changes
  watch: {
    /**
     * Watch for changes in the selected platform
     *
     * @returns {void}
     */
    selectedPlatform() {
      // Use helper function
      this.computeData()
    }
  },

  // Define the local methods
  methods: {
    computeData() {
      // If there are no posts
      if (this.daywise.posts.length === 0) {
        // Stop further execution
        return
      }

      // Get the minimum date for this campaign
      const startDate = dayjs(this.overview.model.start_date)
      // Get the maximum date for this campaign
      const endDate = dayjs(this.overview.model.end_date)

      // Get the minimum post date value
      const minPostDate = dayjs(this.daywise.posts[0].date)
      // Get the maximum post date value
      const maxPostDate = dayjs(this.daywise.posts[this.daywise.posts.length - 1].date)

      // Get the minimum date value by comparing the start date and the min post date
      const minDate = startDate.isBefore(minPostDate) ? startDate : minPostDate
      // Get the maximum date value by comparing the end date and the max post date
      const maxDate = endDate.isAfter(maxPostDate) ? endDate : maxPostDate

      // The daywise posts and influencers count array
      const dayWisePosts = []
      const dayWiseInfluencers = []

      // Go through each day between the min and max date, and add it to the dates array
      for (let date = minDate; date <= maxDate; date = dayjs(date).add(1, "day")) {
        // Get the posts value for this date
        const post = this.daywise.posts.find((item) => dayjs(item.date).isSame(date, "day"))

        // Push it to the list
        dayWisePosts.push([
          date.unix() * 1000,
          post ? post.count : 0
        ])

        // Get the influencers value for this date
        const influencer = this.daywise.influencers.find((item) => dayjs(item.date).isSame(date, "day"))

        // Push it to the list
        dayWiseInfluencers.push([
          date.unix() * 1000,
          influencer ? influencer.count : 0,
          influencer ? influencer.picture : null
        ])
      }

      // Append the values
      this.posts = dayWisePosts
      this.influencers = dayWiseInfluencers
    }
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // Use helper function
    this.computeData()
  }
}
</script>

<style lang="stylus" scoped>
.stats-container
  flex-wrap wrap
  border-bottom 2px solid #f4f6f8

  .stat-item
    flex-grow 1
    min-width 140px
    border-right 2px solid #f4f6f8

    &:last-child
      border-right none

    &__title
      font-size 1.5em
      font-weight bold
      line-height 1.5

    &__text
      font-size 0.85em
      font-weight 600

      .v-icon
        font-size 1.4em
</style>
